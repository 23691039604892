import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_lateral_menu = _resolveComponent("lateral-menu")!
  const _component_base_background = _resolveComponent("base-background")!
  const _component_transition_scale_fade = _resolveComponent("transition-scale-fade")!
  const _component_the_status_banner = _resolveComponent("the-status-banner")!
  const _component_tipti_alert = _resolveComponent("tipti-alert")!
  const _component_alert_retailer_close = _resolveComponent("alert-retailer-close")!
  const _component_alert_retailer_first_time = _resolveComponent("alert-retailer-first-time")!
  const _component_ProfileForm = _resolveComponent("ProfileForm")!
  const _component_affiliation_alert = _resolveComponent("affiliation-alert")!
  const _component_AlertShareData = _resolveComponent("AlertShareData")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($setup.retailerSelected)
      ? (_openBlock(), _createElementBlock("div", {
          key: $data.reset,
          class: _normalizeClass(["main-products", {
            'main-products--block': _ctx.$route.meta.is_detail_product || _ctx.$route.meta.story_type,
        }])
        }, [
          (_ctx.$route.meta.is_detail_product || _ctx.$route.meta.story_type)
            ? (_openBlock(), _createBlock(_component_router_view, { key: 0 }))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createVNode(_component_transition_scale_fade, null, {
                  default: _withCtx(() => [
                    (_ctx.isMobileMenuOpen)
                      ? (_openBlock(), _createBlock(_component_base_background, {
                          key: 0,
                          center: false,
                          onClick: $options.closeMenu
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_lateral_menu, {
                              mobile: "",
                              onCloseMenu: $options.closeMenu,
                              onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
                            }, null, 8, ["onCloseMenu"])
                          ]),
                          _: 1
                        }, 8, ["onClick"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }),
                _createVNode(_component_lateral_menu, {
                  class: _normalizeClass({ menu: $data.zoomResolution }),
                  mobile: false
                }, null, 8, ["class"]),
                _createElementVNode("div", {
                  class: _normalizeClass(["main-products__content", { zoom: $data.zoomResolution }])
                }, [
                  ($setup.showBannerOrder)
                    ? (_openBlock(), _createBlock(_component_the_status_banner, { key: 0 }))
                    : _createCommentVNode("", true),
                  (_openBlock(), _createBlock(_component_router_view, {
                    key: _ctx.$route.fullPath
                  }))
                ], 2)
              ], 64))
        ], 2))
      : _createCommentVNode("", true),
    _createVNode(_component_tipti_alert, {
      "alert-information": $data.alertData,
      "show-alert": $data.showAlertLeaveExtraItems,
      onOnClose: _cache[1] || (_cache[1] = ($event: any) => ($data.showAlertLeaveExtraItems = false)),
      onOnTextAction: $options.leave,
      onOnMainAction: _cache[2] || (_cache[2] = ($event: any) => ($data.showAlertLeaveExtraItems = false))
    }, null, 8, ["alert-information", "show-alert", "onOnTextAction"]),
    ($data.showAlertCloseRetailer)
      ? (_openBlock(), _createBlock(_component_alert_retailer_close, {
          key: 1,
          "alert-type": $data.alertRetailerClose,
          onOnClose: $options.closeAlertRetailerClosing
        }, null, 8, ["alert-type", "onOnClose"]))
      : _createCommentVNode("", true),
    ($data.alertFirstTime)
      ? (_openBlock(), _createBlock(_component_alert_retailer_first_time, {
          key: 2,
          "alert-first-time": $data.alertFirstTime,
          onOnClose: $options.closeAlertFirstTime
        }, null, 8, ["alert-first-time", "onOnClose"]))
      : _createCommentVNode("", true),
    ($setup.showProfileModal)
      ? (_openBlock(), _createBlock(_component_ProfileForm, {
          key: 3,
          "show-alert-complete-profile": false,
          "registration-by": "AFFILIATION",
          onOnAccept: $setup.handleRegistration,
          onOnClose: $setup.closeProfileModal
        }, null, 8, ["onOnAccept", "onOnClose"]))
      : _createCommentVNode("", true),
    ($setup.showAlertAffiliation)
      ? (_openBlock(), _createBlock(_component_affiliation_alert, {
          key: 4,
          onOnFinish: _cache[3] || (_cache[3] = ($event: any) => ($setup.showBannerOrder = true))
        }))
      : _createCommentVNode("", true),
    ($data.canShowAlertShareData)
      ? (_openBlock(), _createBlock(_component_AlertShareData, { key: 5 }))
      : _createCommentVNode("", true)
  ], 64))
}