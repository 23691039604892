<template>
    <RetailerNotFound v-if="showNoRetailers" />
    <template v-else>
        <section v-if="isAuth" class="user-info">
            <TheGreeting class="order-retailer--greeting" />
            <TheUserId class="order-retailer--greeting" />
        </section>
        <retailer-filter @error="errorTypes = true" @loaded="retailersTypeRequestEnded = true" />
        <TransitionHorizontalFade mode="out-in">
            <section v-if="filteredRetailers?.length" :key="filterApplied.id" class="retailers-category">
                <RetailerCard
                    v-for="retailer in filteredRetailers"
                    :key="retailer.id"
                    :retailer="retailer"
                    :section="retailerSections.OTHER_RETAILERS"
                />
            </section>
        </TransitionHorizontalFade>
        <template v-if="!filteredRetailers?.length">
            <BannerPromotionCarouselInfinite />
            <TheYourRetailer @loaded="yourRetailersRequestEnded = true" />
            <template v-if="retailersTypeFilters?.length">
                <TheOtherRetailer v-for="filter in retailersTypeFilters" :key="filter.id" :retailers-filter="filter" />
            </template>
        </template>
        <TheOtherRetailerSkeleton v-if="!retailersTypeFilters.length" />
    </template>
</template>
<script lang="ts" setup>
import TheGreeting from '@/modules/retailers/components/TheGreeting.vue';
import TheUserId from '@/modules/retailers/components/TheUserId.vue';
import { useUser } from '@/composables/useUser';
import { useRetailer } from '@/modules/retailers/composables/useRetailer';
import TheYourRetailer from '@/modules/retailers/components/yourRetailers/TheYourRetailer.vue';
import RetailerFilter from '@/modules/retailers/components/retailerFilter/RetailerFilter.vue';
import { computed, onMounted, ref, watch } from 'vue';
import RetailerNotFound from '@/modules/retailers/components/RetailerNotFound.vue';
import TheOtherRetailer from '@/modules/retailers/components/otherRetailers/TheOtherRetailer.vue';
import TheOtherRetailerSkeleton from '@/modules/retailers/components/otherRetailers/TheOtherRetailerSkeleton.vue';
import RetailerCard from '@/components/cards/RetailerCard.vue';
import { retailerSections } from '@/modules/retailers/enums/retailerSections';
import { useCart } from '@/composables/useCart';
import { useApp } from '@/composables/useApp';
import TransitionHorizontalFade from '@/modules/core/components/transition/TransitionHorizontalFade.vue';
import BannerPromotionCarouselInfinite from '@/modules/retailers/components/banners/BannerPromotionCarouselInfinite.vue';

const emit = defineEmits<(e: 'hasRetailers', value: boolean) => void>();

const { $route } = useApp();
const { fetchQuickCart } = useCart();
const { isAuth } = useUser();
const { retailersTypeFilters: retailerCategories, allRetailers, filteredRetailers, filterApplied } = useRetailer();
const retailersTypeRequestEnded = ref(false);
const yourRetailersRequestEnded = ref(false);
const errorTypes = ref(false);

const showNoRetailers = computed(() => {
    if (errorTypes.value) return true;
    if (!retailersTypeRequestEnded.value || !yourRetailersRequestEnded.value) return false;
    return !allRetailers.value?.length;
});

const retailersTypeFilters = computed(() => retailerCategories.value?.filter((retailer) => !retailer.isAllRetailer));

watch(
    () => $route.path,
    () => {
        fetchQuickCart();
    },
    {
        immediate: true,
    },
);

watch(
    showNoRetailers,
    (newValue) => {
        emit('hasRetailers', !newValue);
    },
    {
        immediate: true,
    },
);

onMounted(() => {
    filterApplied.value = undefined;
});
</script>
<style lang="scss">
@import 'retailers.scss';

.list-move, /* apply transition to moving elements */
.list-enter-active, .list-leave-active {
    transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
    opacity: 0;
    transform: translateX(30px);
}

/* ensure leaving items are taken out of layout flow so that moving   animations can be calculated correctly. */
.list-leave-active {
    position: absolute;
}

.slide-fade-enter-active {
    transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateX(20px);
    opacity: 0;
}

.retailers-promotions--skeleton {
    height: 10rem;
    width: 100%;
    max-width: 1200px;
    margin: 1rem auto;
}
</style>
