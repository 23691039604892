import {
    RestRetailersInfo,
    YourRetailersInfo,
    YourRetailersSoviInfo,
} from '@/modules/retailers/models/OtherRetailersModel';
import { RetailerAvailabilityModel } from '@/modules/retailers/models/RetailerAvailabilityModel';
import { HourModel } from '@/models/retailers/HourModel';
import { DescriptionModel, toDescriptionModel } from '@/models/retailers/DescriptionModel';
import { logger } from '@/utils/logger';
import TimeDeliveryModel from '@/models/checkout/TimeDeliveryModel';
import { CartModel } from '@/models/cart/CartModel';
import { toRetailerModel } from '@/modules/retailers/models/RetailerModel';
import { axiosInstance } from '@/modules/core/infraestructure/ApiClient';
import { RETAILER_FILTER } from '@/modules/core/constants';
import { RetailerFilter } from '@/modules/retailers/models/RetailerFilterModel';

export default {
    async yourRetailers({ commit, rootGetters, getters }): Promise<void> {
        try {
            const sectorId =
                rootGetters['user/userLocation']?.sector?.id ??
                rootGetters['location/addressDefault']?.sector?.id ??
                rootGetters['user/user']?.currentSector?.id;
            if (!sectorId) return;
            commit('yourRetailers', []);
            commit('cambrellas/cambrellasModalWasShowed', false, { root: true });

            const [response, responseSovi] = await Promise.all([
                axiosInstance().get<YourRetailersInfo>(`v3/sector/${sectorId}/retailers`, {
                    params: {
                        size: 3,
                        retailer_type_id: 0,
                        show_care_information: true,
                        show_franchise: true,
                    },
                }),
                axiosInstance().get<YourRetailersSoviInfo[]>(`v3/sector/${sectorId}/retailers`, {
                    params: {
                        only_sovis: true,
                        show_care_information: true,
                        show_franchise: true,
                    },
                }),
            ]);

            const { data } = response;
            const { data: dataSovi } = responseSovi;

            commit('yourRetailers', {
                ...data,
                retailers: [
                    ...new Set(
                        [...data.retailers, ...(dataSovi?.[0]?.retailers ?? [])].map((retailer) =>
                            JSON.stringify(retailer),
                        ),
                    ),
                ].map((string) => JSON.parse(string)),
            });
        } catch (err) {
            throw new Error(err);
        } finally {
            if (rootGetters['deeplink/isFromDeeplink']) commit('deeplink/isFromDeeplink', false, { root: true });
        }
    },
    async getAllRetailersByTypeId(
        { rootGetters },
        {
            retailer_type_id,
            size,
            sector_id,
        }: {
            retailer_type_id: number;
            size: number;
            sector_id: number;
        },
    ): Promise<RestRetailersInfo[]> {
        try {
            const { data } = await rootGetters['network/axios'].get(`v3/sector/${sector_id}/retailers`, {
                params: {
                    retailer_type_id,
                    size,
                    show_care_information: true,
                    show_franchise: true,
                },
            });

            return data;
        } catch (err) {
            logger('RETAILERS_BY_TYPE_ID', err);
        }
    },

    async retailersType({ commit, rootGetters }): Promise<RetailerFilter[]> {
        commit('filters', []);
        const _sectorId =
            rootGetters['user/userLocation']?.sector?.id ??
            rootGetters['location/addressDefault']?.sector?.id ??
            rootGetters['user/user']?.currentSector?.id;
        if (!_sectorId) return;
        const url = `v3/sector/${_sectorId}/retailer-types`;
        const { data } = await rootGetters['network/axios'].get(url);
        const filers: RetailerFilter[] = [RETAILER_FILTER, ...data['retailers_type']];
        if (data['retailers_type']?.length) commit('filters', filers);
        return filers;
    },
    async retailerDetail({ commit, rootGetters }): Promise<void> {
        try {
            const _sectorId =
                rootGetters['user/userLocation']?.sector?.id ??
                rootGetters['location/addressDefault']?.sector?.id ??
                rootGetters['user/user']?.currentSector?.id;
            if (!_sectorId) return;

            const { data } = await rootGetters['network/axios'].get(`v3/retailer_by_id_franchise_sector`, {
                params: {
                    sector_id: _sectorId,
                    retailer_id: rootGetters['retailers/retailerSelected'].id,
                },
            });
            commit('retailerSelected', toRetailerModel(data));
        } catch (err) {
            logger('RETAILERS_DETAIL', err);
        }
    },

    async retailerByFranchiseId({ commit, rootGetters }, franchise_id: number): Promise<void> {
        try {
            const sector_id =
                rootGetters['user/userLocation']?.sector?.id ??
                rootGetters['location/addressDefault']?.sector?.id ??
                rootGetters['user/user']?.currentSector?.id;
            if (!sector_id || !franchise_id) return;
            const { data } = await rootGetters['network/axios'].get(`v3/retailer_by_id_franchise_sector`, {
                params: {
                    sector_id,
                    franchise_id,
                },
            });
            commit('retailerSelected', toRetailerModel(data));
        } catch (err) {
            logger('RETAILERS_DETAIL_BY_FRANCHISE', err);
        }
    },

    async description({ rootGetters, getters }): Promise<DescriptionModel> {
        try {
            const url = `v2/api/retailer/${getters['retailerSelected'].id}/info`;
            const response = await rootGetters['network/axios'].get(url);
            return toDescriptionModel(response.data);
        } catch (err) {
            throw Error(err);
        }
    },
    async retailersAvailability({ rootGetters, commit, dispatch }): Promise<void> {
        try {
            const url = `v2/check_retailer_availability_in/${
                rootGetters['user/userLocation']?.sector?.id ?? rootGetters['location/addressDefault']?.sector?.id
            }/delivery_sector`;
            const { data } = await rootGetters['network/axios'].get(url);
            commit('originalRetailersAvailability', data);
            dispatch('removeRetailerWithOutNormalProducts', data);
        } catch (err) {
            throw Error(err);
        }
    },
    async firstSchedulePerRetailer({ rootGetters, getters, commit }): Promise<void> {
        const allRetailsAvailable: RetailerAvailabilityModel[] = getters['retailersAvailability'];
        if (!allRetailsAvailable?.length) return;
        const urlList = allRetailsAvailable.map((retailer) =>
            rootGetters['network/axios'].get(
                `v2/api/first_delivery/scheduled/retailer/${retailer.retailer_id}/sector/${
                    rootGetters['user/userLocation']?.sector?.id ?? rootGetters['location/addressDefault']?.sector?.id
                }`,
            ),
        );
        const result = await Promise.allSettled(urlList);
        result.forEach((retailer, index) => {
            if (retailer.status === 'fulfilled' && retailer.value?.data?.schedules?.length) {
                commit(
                    'checkout/timeDelivery',
                    new TimeDeliveryModel({
                        retailerId: allRetailsAvailable[index].retailer_id,
                        retailerName: allRetailsAvailable[index].retailer_name,
                        retailerLogo: allRetailsAvailable[index].retailer_icon,
                        date: retailer.value.data.date,
                        express: retailer.value.data['schedules'][0]['express'],
                        deliveryType: retailer.value.data['type'],
                        deliveryFirstDate: retailer.value.data['schedules'][0]['first_hour'],
                        deliverySecondDate: retailer.value.data['schedules'][0]['second_hour'],
                        shippingCost: retailer.value.data['schedules'][0]['price'],
                        originalShippingCost: retailer.value.data['schedules'][0]['original_price'],
                    }),
                    { root: true },
                );
            }
        });
    },
    async firstSchedulePerRetailerPickUp({ rootGetters, getters, commit }): Promise<void> {
        try {
            if (!rootGetters['user/userLocation']?.pickUpCenter?.id) {
                commit('checkout/cleanTimeDelivery', null, { root: true });
                return;
            }
            const url = `v2/api/first_pickup/scheduled/pickup_center/${rootGetters['user/userLocation'].pickUpCenter.id}/`;
            const { data } = await rootGetters['network/axios'].get(url);
            if (data['schedules']?.length)
                commit(
                    'checkout/timeDelivery',
                    new TimeDeliveryModel({
                        retailerId: getters['retailersAvailability'][0].id,
                        retailerName: getters['retailersAvailability'][0].name,
                        retailerLogo: getters['retailersAvailability'][0].icon,
                        date: data['date'],
                        express: data['schedules'][0]['express'],
                        deliveryType: data['type'],
                        deliveryFirstDate: data['schedules'][0]['first_hour'],
                        deliverySecondDate: data['schedules'][0]['second_hour'],
                        shippingCost: data['schedules'][0]['price'],
                        originalShippingCost: data['schedules'][0]['original_price'],
                    }),
                    { root: true },
                );
        } catch (err) {
            throw Error(err);
        }
    },
    async getRetailerHours({ rootGetters }, retailerId: number): Promise<Array<HourModel>> {
        try {
            const url = `v2/api/delivery/scheduled/retailer/${retailerId}/sector/${
                rootGetters['user/userLocation']?.sector?.id ?? rootGetters['location/addressDefault']?.sector?.id
            }`;
            const { data } = await rootGetters['network/axios'].get(url);
            return data;
        } catch (_) {
            throw Error('GET_RETAILER_HOURS');
        }
    },

    async getRetailerHoursPickUp({ rootGetters }): Promise<Array<HourModel>> {
        const url = `v2/api/pickup/scheduled/pickup_center/${rootGetters['user/userLocation'].pickUpCenter.id}/`;
        const { data } = await rootGetters['network/axios'].get(url);
        return data;
    },
    removeRetailerWithOutNormalProducts(
        { rootGetters, commit },
        retailersAvailability: RetailerAvailabilityModel[],
    ): void {
        const cart: CartModel = rootGetters['cart/cart'];
        const retailerIds = cart.retailers
            .filter((retailer) => retailer.products.some((product) => !product.uniqueDelivery))
            .map((retailer) => retailer.id);
        const allRetailersAvailability = retailersAvailability.filter((retailer) => {
            return retailerIds.includes(retailer.retailer_id);
        });
        commit('retailersAvailability', allRetailersAvailability);
    },

    async deferredOptionByRetailer({ rootGetters, getters, commit }): Promise<void> {
        try {
            const retailer_id = getters.retailerSelected?.id;
            if (!retailer_id) return commit('deferredOptionByRetailer', {});
            const { data } = await rootGetters['network/axios'].get(`approximate-franchise-fee/${retailer_id}`);
            commit('deferredOptionByRetailer', data);
        } catch (err) {
            commit('deferredOptionByRetailer', {});
        }
    },
};
