<template>
    <div
        v-if="retailerSelected"
        :key="reset"
        class="main-products"
        :class="{
            'main-products--block': $route.meta.is_detail_product || $route.meta.story_type,
        }"
    >
        <router-view v-if="$route.meta.is_detail_product || $route.meta.story_type" />
        <template v-else>
            <transition-scale-fade>
                <base-background v-if="isMobileMenuOpen" :center="false" @click="closeMenu">
                    <lateral-menu mobile @close-menu="closeMenu" @click.stop />
                </base-background>
            </transition-scale-fade>
            <lateral-menu :class="{ menu: zoomResolution }" :mobile="false" />
            <div class="main-products__content" :class="{ zoom: zoomResolution }">
                <the-status-banner v-if="showBannerOrder" />
                <router-view :key="$route.fullPath" />
            </div>
        </template>
    </div>
    <tipti-alert
        :alert-information="alertData"
        :show-alert="showAlertLeaveExtraItems"
        @on-close="showAlertLeaveExtraItems = false"
        @on-text-action="leave"
        @on-main-action="showAlertLeaveExtraItems = false"
    />
    <alert-retailer-close
        v-if="showAlertCloseRetailer"
        :alert-type="alertRetailerClose"
        @on-close="closeAlertRetailerClosing"
    />
    <alert-retailer-first-time
        v-if="alertFirstTime"
        :alert-first-time="alertFirstTime"
        @on-close="closeAlertFirstTime"
    />

    <ProfileForm
        v-if="showProfileModal"
        :show-alert-complete-profile="false"
        registration-by="AFFILIATION"
        @on-accept="handleRegistration"
        @on-close="closeProfileModal"
    />
    <affiliation-alert v-if="showAlertAffiliation" @on-finish="showBannerOrder = true" />
    <AlertShareData v-if="canShowAlertShareData" />
</template>

<script lang="ts">
import { mapActions, mapGetters } from 'vuex';
import LateralMenu from '../components/lateralMenu/LateralMenu.vue';
import TransitionScaleFade from '@/modules/core/components/transition/TransitionScaleFade.vue';
import { useExtraItems } from '@/modules/extraItems/composables/useExtraItems';
import { useCartType } from '@/modules/core/composable/useCartType';
import { BaseTiptiAlertModelInterface } from '@/models/alerts/AlertOpenRetailer';
import { computed, defineAsyncComponent, ref, watch } from 'vue';
import { AddressModel } from '@/models/address/AddressModel';
import { AlertNames } from '@/enums/alertNames';
import { usePickUp } from '@/composables/usePickUp';
import dayjs from 'dayjs';
import AffiliationAlert from '@/modules/affiliation/components/AffiliationAlert.vue';
import AlertShareData from '@/components/alerts/alertShareData/AlertShareData.vue';
import { useUser } from '@/composables/useUser';
import { storeToRefs } from 'pinia';
import { usePickupStore } from '@/store/usePickupStore';
import { useRetailer } from '@/modules/retailers/composables/useRetailer';
import { appRoutesMap } from '@/router/appRoutesMap';
import { useRoutes } from '@/composables/useRoutes';
import NotificationWithAction from '@/components/alerts/alertWithAction/NotificationWithAction.vue';
import ProfileForm from '@/views/cart/components/profileForm/ProfileForm.vue';
import { useAffiliationAlert } from '@/modules/affiliation/composables/useAffiliationAlert';
import { useApp } from '@/composables/useApp';
import { DeferredOptionByRetailer } from '@/modules/retailers/store/model';

export default {
    name: 'MainProducts',
    components: {
        ProfileForm,
        AlertShareData,
        AffiliationAlert,
        BaseBackground: defineAsyncComponent(() => import('@/modules/core/components/background/BaseBackground.vue')),
        TiptiAlert: defineAsyncComponent(() => import('@/components/alerts/alertTiptiAlert/TiptiAlert.vue')),
        AlertRetailerClose: defineAsyncComponent(
            () => import('@/components/alerts/alertsReailerClose/AlertRetailerClose.vue'),
        ),
        AlertRetailerFirstTime: defineAsyncComponent(
            () => import('@/components/alerts/alertRetailerFirstTime/AlertRetailerFirstTime.vue'),
        ),
        TheStatusBanner: defineAsyncComponent(
            () => import('@/modules/main-products/components/TheStatusOrder/TheStatusOrder.vue'),
        ),
        LateralMenu,
        TransitionScaleFade,
    },
    setup() {
        const { cart } = useExtraItems();
        const {
            deferredOptionByRetailer,
            retailerSelected,
            fetchAlerts,
            fetchRetailerSelected,
            deferredOptionByRetailerValue,
            getRetailerFromFilter,
            retailersTypeFilters,
            minRetailerInfoToRetailerModel,
        } = useRetailer();
        const { isExtraItemCart, changeToMainCart } = useCartType();
        const { isAuth, userLocation: locationSelected } = useUser();
        const { showPickUpAlert, temporalRetailer } = storeToRefs(usePickupStore());
        const { canShowDeliveryOptions, retailerWasChanged } = usePickUp({ showButtonOption: true });
        const { isPageAllCategories } = useRoutes();
        const showProfileModal = ref(false);
        const { showAlertCompleteProfile, init } = useAffiliationAlert();
        const { $t, notifier, $route, $router, $store } = useApp();

        const handleRegistration = () => {
            showProfileModal.value = false;
            notifier({
                type: 'SUCCESS',
                body: $t('txt.successful-registration-access-to-benefits'),
            });
            init();
        };

        deferredOptionByRetailerValue.value = {} as DeferredOptionByRetailer;

        const showBannerOrder = ref(false);

        if (isExtraItemCart.value) showBannerOrder.value = true;
        else {
            const retailer = getRetailerFromFilter.value($route.params.retailer as string);
            if (!retailersTypeFilters.value.length || !retailer) {
                $router.replace({
                    name: appRoutesMap.home,
                });
                return;
            }
            $store.commit('location/setLocationSelectedAsDelivery');
            retailerSelected.value = minRetailerInfoToRetailerModel(retailer);
            fetchRetailerSelected().then(() => {
                fetchAlerts();
                deferredOptionByRetailer();
            });
        }

        const openModalProfile = () => {
            showProfileModal.value = true;
            showAlertCompleteProfile.value = false;
        };

        const showAlertAffiliation = computed<boolean>(
            () => isPageAllCategories.value && !!retailerSelected.value?.franchise?.id,
        );

        const shouldShowCompleteProfileAlert = computed<boolean>(
            () => false,
            // () => showAlertCompleteProfile.value && isPageAllCategories.value,
        );

        const closeProfileModal = () => {
            showProfileModal.value = false;
            notifier({
                type: 'INFO',
                body: $t('txt.registration-pending-profile'),
            });
        };

        watch(
            shouldShowCompleteProfileAlert,
            (value) => {
                if (!value) return;
                notifier({
                    type: 'SUCCESS',
                    component: NotificationWithAction,
                    title: $t('txt.complete-register-title'),
                    body: $t('txt.complete-register-description'),
                    timeout: 5000,
                    actions: {
                        click: () => openModalProfile(),
                    },
                });
            },
            {
                immediate: true,
            },
        );

        return {
            isAuth,
            cart,
            isExtraItemCart,
            changeToMainCart,
            canShowDeliveryOptions,
            retailerWasChanged,
            showPickUpAlert,
            temporalRetailer,
            retailerSelected,
            locationSelected,
            showAlertAffiliation,
            showAlertCompleteProfile,
            shouldShowCompleteProfileAlert,
            showProfileModal,
            openModalProfile,
            closeProfileModal,
            handleRegistration,
            showBannerOrder,
        };
    },
    data() {
        return {
            showAlertLeaveExtraItems: false,
            nextRoute: undefined,
            alertData: undefined,
            reset: 0,
            showAlertCloseRetailer: false,
            alertRetailerClose: undefined,
            alertFirstTime: undefined,
            alertTimer: undefined,
            zoomResolution: false,
            canShowAlertShareData: false,
            windowWidth: window.innerWidth,
        };
    },
    computed: {
        ...mapGetters({
            isMobileMenuOpen: 'categories/isMobileCategoryMenuOpen',
            products: 'extraItems/products',
            cartType: 'app/cartType',
        }),
    },
    watch: {
        cartType(newValue: boolean, oldVal: boolean) {
            if (newValue === oldVal) return;
            if (newValue) this.reset++;
        },
        async locationSelected(newLocation: AddressModel, oldLocation: AddressModel) {
            if (newLocation?.sector?.id === oldLocation?.sector?.id) return;
            this.$router.push({ name: appRoutesMap.home });
        },
    },
    async created(): Promise<void> {
        this.alertData = {
            title: this.$t('txt.sure_to_leave'),
            description: this.$t('txt.any_product_added'),
            button_text: this.$t('txt.stay_here'),
            link_text: this.$t('txt.yes_quit'),
        } as BaseTiptiAlertModelInterface;
        if (!this.retailerWasChanged) {
            this.temporalRetailer = undefined;
            const _location: AddressModel = { ...this.locationSelected };
            _location.isPickUpMode = false;
            _location.pickUpCenter = null;
            this.$store.commit('user/userLocation', _location);
        }
        if (this.retailerSelected?.isPickupAvailable) await this.fetchQuickCart();
        this.canShowAlertShareData = false;
        this.alertFirstTime = undefined;
        if (this.retailerSelected?.showAlertFirstTime)
            this.alertFirstTime = this.alerts?.find((alert) => alert.name === AlertNames.firstTime);
        this.currentAlertRetailerClosings();
        if (!this.alertFirstTime && this.isAuth) this.canShowAlertShareData = true;
    },
    mounted(): void {
        window.addEventListener('resize', this.zoomPage);
        this.zoomPage();
        const showPickUp = this.$route.params.pickup === 'true';
        if (!showPickUp) return;
        if (!this.retailerSelected?.isPickupAvailable) return;
        if (!this.canShowDeliveryOptions) return;
        this.showPickUpAlert = true;
        this.temporalRetailer = this.retailerSelected;
        const _location: AddressModel = { ...this.locationSelected };
        _location.isPickUpMode = false;
        _location.pickUpCenter = null;
        this.$store.commit('user/userLocation', _location);
    },
    methods: {
        ...mapActions({
            updateAlertFirstTime: 'alerts/updateAlertFirstTime',
            fetchQuickCart: 'cart/fetchQuickCart',
        }),
        closeAlertFirstTime() {
            this.updateAlertFirstTime();
            this.alertFirstTime = undefined;
            this.canShowAlertShareData = true;
        },
        closeAlertRetailerClosing(): void {
            this.showAlertCloseRetailer = false;
            if (this.alertRetailerClose.name === AlertNames.retailerClosing) return;
            this.currentAlertRetailerClosings();
        },
        closeMenu(): void {
            this.$store.commit('categories/isMobileCategoryMenuOpen', false);
        },
        currentAlertRetailerClosings(): void {
            this.showAlertCloseRetailer = false;
            if (!this.retailerSelected?.closeTime) return;
            const toDay = dayjs();
            const temporalRet = { ...this.retailerSelected };
            const [hours, minutes, seconds] = temporalRet.closeTime.split(':');
            const closeTime = toDay.hour(+hours).minute(+minutes).second(+seconds);
            this.alertRetailerClose = undefined;
            if (toDay.isAfter(closeTime)) return;
            if (toDay.isBefore(closeTime.subtract(this.retailerSelected?.timeAlertClose, 'minute'))) {
                this.startTimer(AlertNames.retailerClosingSoon);
                return;
            }
            this.startTimer(AlertNames.retailerClosing);
        },
        leave(): void {
            this.changeToMainCart();
            this.$router.push({
                path: this.nextRoute.path,
                replace: true,
                params: this.nextRoute.params,
                query: this.nextRoute.query,
            });
        },
        startTimer(alertType: AlertNames): void {
            this.alertRetailerClose = this.alerts?.find((alert) => alert.name === alertType);
            if (!this.alertRetailerClose || !this.retailerSelected?.closeTime) return;
            const toDay = dayjs();
            const [hours, minutes, seconds] = this.retailerSelected.closeTime.split(':');
            const closeTime = toDay.hour(hours).minute(minutes).second(seconds);
            let remainingTime;

            if (alertType == AlertNames.retailerClosingSoon) {
                const time = closeTime.subtract(this.retailerSelected?.timeAlertClose, 'minute');
                remainingTime = time.diff(toDay, 'milliseconds');
            } else remainingTime = closeTime.diff(toDay, 'milliseconds');
            this.timer = setTimeout(() => {
                this.showAlertCloseRetailer = true;
            }, remainingTime);
        },
        zoomPage() {
            let screenWidth = window.screen.width;
            let screenHeight = window.screen.height;
            if (screenWidth <= 1280 && screenHeight <= 720) {
                this.zoomResolution = true;
                return;
            }
            this.zoomResolution = false;
        },
    },
    beforeRouteLeave(to, from, next) {
        if (!this.isExtraItemCart || !this.products?.length) return next();
        this.showAlertLeaveExtraItems = true;
        this.nextRoute = to;
        return next(false);
    },
    unmounted() {
        clearTimeout(this.timer);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.zoomPage);
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.main-products {
    display: grid;
    grid-template-columns: 250px calc(100vw - 275px);

    &--block {
        display: block;
    }

    @include tablet {
        display: block;
    }
}

.main-products__stories-minibanners {
    display: grid;
    grid-template-columns: 60% minmax(0, 1fr);
    align-items: center;
    gap: $margin-md;
    row-gap: $margin-md;
    margin: $margin-md 0;
    @include tablet {
        display: flex;
        flex-direction: column;
    }
}

.zoom {
    display: block;
    zoom: 60%;
    width: 100%;
}

.menu {
    display: flex;
    zoom: 60%;
    width: auto;
    height: auto;
    @include tablet {
        display: none;
    }
}
</style>
