import { createRouter, createWebHistory } from 'vue-router';
import routes from './routes';
import store from '@/store';
import { logger } from '@/utils/logger';
import { WalletMetaTypes } from '@/views/wallet/WalletRoutes';
import { RestRetailersInfo } from '@/modules/retailers/models/OtherRetailersModel';

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) return savedPosition;
        return { left: 0, top: 0, behavior: 'smooth' };
    },
});

declare module 'vue-router' {
    interface RouteMeta {
        require_retailer?: boolean;
        fetch_tipti_tc?: boolean;
        show_notification_rate?: boolean;
        show_order_to_rate?: boolean;
        use_custom_header?: boolean;
        use_custom_footer?: boolean;
        require_auth?: boolean;
        show_router_view?: boolean;
        send_gift?: boolean;
        wallet_type?: WalletMetaTypes;
        breadcrumbs?: string[];
        is_detail_product?: boolean;
        preserve_banner?: boolean;
        banner_type?: 'products' | 'discount' | 'category' | 'promotions';
        story_type?: 'products' | 'discount' | 'category';
        subsection?: 'list' | 'address' | 'payment';
        isCategoryList?: boolean;
        isInOrderPage?: boolean;
        show_recommended_products?: boolean;
        not_fetch_info_retailer?: boolean;
    }
}

router.beforeEach(async (to, from, next) => {
    if (to.meta?.not_fetch_info_retailer) return next();

    if (store.getters['retailers/filters'].filter((type) => !type.isAllRetailer)?.length) return next();
    if (!store.getters['user/userLocation']?.sector?.id) await store.dispatch('user/getProfile');

    const getRetailerType = async (sector_id: number, retailer_type_id: number) => {
        const response: RestRetailersInfo[] | undefined = await store.dispatch('retailers/getAllRetailersByTypeId', {
            retailer_type_id,
            size: 3,
            sector_id,
        });
        const retailers = response?.at(0);
        if (!retailers?.retailers?.length) {
            store.commit(
                'retailers/filters',
                store.getters('retailers/filters')?.filter((filter) => filter.id !== retailer_type_id),
            );
            return;
        }
        const allRetailers = [...store.getters['retailers/otherRetailers'], retailers];
        store.commit('retailers/otherRetailers', allRetailers);
    };

    if (store.getters['user/userLocation']?.sector?.id) {
        const types = await store.dispatch('retailers/retailersType');
        if (types?.length)
            await Promise.allSettled(
                types
                    .filter((filter) => !filter.isAllRetailer)
                    .map((filter) => getRetailerType(store.getters['user/userLocation']?.sector?.id, filter.id)),
            );
    }
    return next();
});

router.beforeResolve(async (to, from, next) => {
    store.commit('app/currentRoutePath', to.fullPath);

    if (to.meta.require_auth && !store.getters['user/isAuth']) {
        store.commit('user/requireAuth', true);
        return next({ path: '/', replace: true });
    }
    if (to.meta.story_type) store.commit('promotions/bannerSelected');
    if (to.meta.banner_type) store.commit('stories/selectedStory');
    if (!to.meta.banner_type && !to.meta.story_type && !to.meta.is_detail_product && !to.meta.preserve_banner) {
        store.commit('promotions/bannerSelected');
        store.commit('stories/selectedStory');
    }
    next();
});

const updateCanonical = () => {
    const canonicalLink = document.querySelector('link[rel="canonical"]');
    if (canonicalLink) {
        canonicalLink.setAttribute('href', window.location.href);
        return;
    }
    const link = document.createElement('link');
    link.setAttribute('rel', 'canonical');
    link.setAttribute('href', window.location.href);
    document.head.appendChild(link);
};

router.afterEach((to, from, failure) => {
    if (failure) logger('navigation failure', { to, from, failure });
    else updateCanonical();
});

export default router;
