<template>
    <section class="retailer-type-row">
        <p v-translate="retailersFilter.name" class="retailer_type_name" />
        <section class="retailers">
            <template v-for="retailer in otherRetailers?.at(0)?.retailers ?? []" :key="retailer.id">
                <RetailerCard :retailer="retailer" :section="retailerSections.OTHER_RETAILERS" />
            </template>
            <template v-if="isLoading">
                <base-bone v-for="i in 2" :key="i" class="retailers--skeleton" />
            </template>
        </section>
    </section>
</template>
<script lang="ts" setup>
import { useStore } from 'vuex';
import type { PropType } from 'vue';
import { ref, shallowRef } from 'vue';
import { RetailerFilter } from '@/modules/retailers/models/RetailerFilterModel';
import { RestRetailersInfo } from '@/modules/retailers/models/OtherRetailersModel';
import { useRetailer } from '@/modules/retailers/composables/useRetailer';
import { retailerSections } from '@/modules/retailers/enums/retailerSections';
import BaseBone from '@/components/skeletons/BaseBone.vue';
import RetailerCard from '@/components/cards/RetailerCard.vue';
import { useUser } from '@/composables/useUser';

const $store = useStore();
const props = defineProps({
    retailersFilter: {
        type: Object as PropType<RetailerFilter>,
        required: true,
    },
});
const { otherRetailers: otherRetailersStore } = useRetailer();
const { userLocation } = useUser();

const isLoading = ref(true);
const otherRetailers = shallowRef<RestRetailersInfo[]>([]);
const init = async (): Promise<void> => {
    try {
        if (props.retailersFilter.isAllRetailer) return;

        otherRetailers.value = await $store.dispatch('retailers/getAllRetailersByTypeId', {
            retailer_type_id: props.retailersFilter.id,
            size: 3,
            sector_id: userLocation.value.sector.id,
        });

        if (otherRetailers?.value?.length > 0) {
            otherRetailersStore.value = [...otherRetailersStore.value, ...otherRetailers.value];
        } else {
            let allFilter = $store.getters['retailers/filters'];
            allFilter = allFilter.filter((fil) => fil.id !== props.retailersFilter?.id);
            $store.commit('retailers/filters', allFilter);
        }
    } finally {
        isLoading.value = false;
    }
};
init();
</script>

<style lang="scss" scoped>
@import 'other-retailers';
</style>
