import type { ExtractPropTypes } from 'vue';
import { onMounted, onUnmounted, ref, watch } from 'vue';
import { RequestState } from '@/modules/core/types/WorkingState';
import { debouncedWatch, useIntersectionObserver } from '@vueuse/core';
import { useWindowSize } from '@vueuse/core/index';
import { useUser } from '@/composables/useUser';

interface Props {
    autoplay?: boolean;
    interval?: number;
    state: RequestState;
}

type EmitType = (event: 'reached-end') => void;

export function useCarousel(props: Readonly<ExtractPropTypes<Props>>, emit: EmitType) {
    const slider = ref<HTMLElement>();
    const lastElement = ref<HTMLElement>();
    const firstElement = ref<HTMLElement>();
    const showLeftArrow = ref(false);
    const { baseConfiguration } = useUser();
    const showRightArrow = ref(props.state === 'NONE');
    let timer: any = null;
    const { width } = useWindowSize();

    useIntersectionObserver(firstElement, ([{ isIntersecting: showed }]) => {
        if (showLeftArrow.value && showed) return;
        if (!showLeftArrow.value && !showed) return;
        showLeftArrow.value = !showed;
    });

    useIntersectionObserver(lastElement, ([{ isIntersecting: showed }]) => {
        if (showRightArrow.value && showed) return;
        if (!showRightArrow.value && !showed) return;
        showRightArrow.value = !showed;
    });

    const move = (isLeft: boolean) => {
        if (!slider.value) return;
        slider.value.scrollBy({
            left: slider.value.clientWidth * (isLeft ? -1 : 1),
            behavior: 'smooth',
        });
        if (!isLeft) fetchPage();
        autoPlay();
    };

    const fetchPage = () => {
        if (!slider.value) return;
        const maxRightSide = slider.value.scrollLeft + slider.value.clientWidth + 50;
        if (maxRightSide < slider.value.scrollWidth || props.state === 'LOADING' || props.state === 'LOAD-ENDED')
            return;
        emit('reached-end');
    };

    const scroll = () => {
        if (!slider.value) return;
        slider.value.onscroll = async () => {
            if (!slider.value) return;
            updateArrows();
            fetchPage();
        };
    };

    const clearTimer = () => {
        if (!props.autoplay) return;
        if (!timer) return;
        clearInterval(timer);
        timer = null;
    };

    const autoPlay = () => {
        if (!props.autoplay) return;
        clearTimer();
        timer = setInterval(
            () => {
                if (!slider.value) return;
                showRightArrow.value = slider.value?.scrollLeft + slider.value?.clientWidth < slider.value?.scrollWidth;
                showRightArrow.value
                    ? slider.value.scrollBy({
                          left: slider.value?.clientWidth,
                          behavior: 'smooth',
                      })
                    : slider.value?.scrollTo({
                          left: 0,
                          behavior: 'smooth',
                      });
            },
            props.interval ?? baseConfiguration.value?.bannerTimeDelay ?? 4000,
        );
    };

    const ensureFillAllCarousel = () => {
        if (props.state === 'LOAD-ENDED' || props.state === 'LOADING' || props.state === 'FIRST_LOAD') return;
        if (showRightArrow.value) return;
        emit('reached-end');
    };

    onUnmounted(() => {
        clearTimer();
        if (!slider.value) return;
        slider.value.onscroll = null;
    });

    onMounted(() => {
        scroll();
        if (!slider.value) return;
        showRightArrow.value = slider.value.scrollLeft + slider.value.clientWidth < slider.value.scrollWidth;
        ensureFillAllCarousel();
        autoPlay();
    });

    const updateArrows = (): void => {
        if (!slider.value) return;
        showRightArrow.value = slider.value?.scrollLeft + slider.value?.clientWidth < slider.value.scrollWidth - 10;
        showLeftArrow.value = slider.value?.scrollLeft > 0;
        lastElement.value = slider.value.lastElementChild as HTMLElement;
        firstElement.value = slider.value.firstElementChild as HTMLElement;
    };

    debouncedWatch(
        width,
        () => {
            updateArrows();
        },
        {
            debounce: 500,
        },
    );

    watch(
        () => props.state,
        (): void => {
            updateArrows();
        },
        {
            flush: 'post',
        },
    );

    return {
        slider,
        showLeftArrow,
        showRightArrow,
        lastElement,
        move,
        autoPlay,
        clearTimer,
        fetchPage,
        scroll,
    };
}
