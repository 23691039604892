import { RouteRecordRaw } from 'vue-router';
import { appRoutesMap } from '@/router/appRoutesMap';

export const catalogRoutes: Array<RouteRecordRaw> = [
    {
        path: '/catalog-ficoa',
        name: appRoutesMap.ficoaPage,
        component: () => import('@/modules/catalogs/pages/Catalog.vue'),
        meta: {
            use_custom_header: true,
            use_custom_footer: true,
            not_fetch_info_retailer: true,
        },
    },
];
