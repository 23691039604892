import { useHead, useHeadSafe, useSeoMeta } from '@unhead/vue';
import { headScripts } from '@/utils/headScript';
import { useI18n } from 'vue-i18n';
import { useUser } from '@/composables/useUser';

export const useSeo = () => {
    const { origin } = window.location;
    const { userLanguage } = useUser();
    const { t } = useI18n();

    const seoMeta = () => {
        return useSeoMeta({
            googlebot: 'notranslate',
            robots: {
                index: process.env.VUE_APP_API_ENV === 'PROD',
                noindex: process.env.VUE_APP_API_ENV !== 'PROD',
            },
        });
    };

    const seoHead = () => {
        return useHeadSafe({
            htmlAttrs: {
                lang: userLanguage.value,
            },
        });
    };

    const script = [
        {
            src: headScripts.googletagmanager.condition ? headScripts.googletagmanager.src : '',
            defer: true,
            async: true,
        },
        {
            textContent: headScripts.gtagDataLayer.condition ? headScripts.gtagDataLayer.content : '',
        },
        {
            textContent: headScripts.segment.condition ? headScripts.segment.content : '',
        },
        {
            textContent: headScripts.metricool.condition ? headScripts.metricool.content : '',
        },
    ];

    const metaTageHome = () => {
        useHead({
            title: `Tipti | ${t('seo.tipti')}`,
            meta: [
                { property: 'og:type', content: 'webPage' },
                { name: 'description', content: t('seo.tipti__description') },
                { name: 'keywords', content: t('seo.page_home_keywords') },
            ],
            script,
        });
        seoMeta();
        seoHead();
    };

    const metaAboutUs = () => {
        useHead({
            title: t('seo.page_about'),
            meta: [
                { property: 'og:type', content: 'webPage' },
                { name: 'description', content: t('seo.page_about_description') },
                { name: 'keywords', content: t('seo.page_about_keywords') },
            ],
            script,
        });
        seoMeta();
        seoHead();
    };

    const metaPromotions = () => {
        useHead({
            title: t('seo.page_promotion'),
            meta: [
                { property: 'og:type', content: 'webPage' },
                { name: 'description', content: t('seo.page_promotion_description') },
                { name: 'keywords', content: t('seo.page_promotion_keywords') },
            ],
            script,
        });
        seoMeta();
        seoHead();
    };

    return {
        metaTageHome,
        metaAboutUs,
        metaPromotions,
    };
};
