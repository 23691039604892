<template>
    <router-link
        :to="{
            name: appRoutesMap.retail.home,
            params: {
                retailer: retailer.name,
            },
        }"
        @click.stop="
            navigateToRetailer({
                section: retailerSections.OTHER_RETAILERS,
                retailer: retailer,
            })
        "
    >
        <article class="retailer-card cursor-pointer">
            <ImageLoader class="retailer-card__img" :alt-text="retailer.name" :delay="400" :src="retailer.image">
                <template #loader>
                    <base-bone class="retailer-card__img--skeleton" />
                </template>
            </ImageLoader>
            <retailer-data-tile
                :delivery-pickup-available="retailer.pickup_available"
                :delivery-range="retailer.delivery_range"
                :name="retailer.name"
            />
            <icon-back-no-border
                class="retailer-card__arrow-next cursor-pointer"
                :background-fill="'#FFF4E7'"
                :color="'#FF9012'"
                :icon-name="$t('icon.forward')"
                :size="0.8"
            />
        </article>
    </router-link>
</template>

<script lang="ts" setup>
import { MinRetailerInfo } from '@/modules/retailers/models/OtherRetailersModel';
import { PropType } from 'vue';
import RetailerDataTile from '@/modules/retailers/components/shared/RetailerDataTile.vue';
import IconBackNoBorder from '@/components/icons/IconBackNoBorder.vue';
import ImageLoader from '@/modules/core/components/loaders/ImageLoader.vue';
import BaseBone from '@/components/skeletons/BaseBone.vue';
import { appRoutesMap } from '@/router/appRoutesMap';
import { useRetailer } from '@/modules/retailers/composables/useRetailer';
import { retailerSections } from '@/modules/retailers/enums/retailerSections';

defineProps({
    retailer: {
        type: Object as PropType<MinRetailerInfo>,
        required: true,
    },
    section: {
        type: String as PropType<retailerSections>,
        required: true,
    },
});

const { navigateToRetailer } = useRetailer();
</script>

<style lang="scss" scoped>
@import '@/assets/scss/index.scss';

.retailer-card {
    height: 5rem;
    display: grid;
    grid-template-columns: 30% 1fr 10%;
    column-gap: 15px;
    align-items: center;
    box-sizing: border-box;
    border-radius: 8px;

    @include phone {
        column-gap: 10px;
        min-width: 15rem;
        margin: $margin-none;
        height: 4rem;
    }

    &__img {
        border-radius: $radius-lg;
        object-fit: cover;
        width: 100%;
        height: inherit;
        flex-grow: 0.4;
        @include box-shadow;

        &--skeleton {
            height: inherit;
            width: 100%;
        }
    }

    &__arrow-next {
        transform: rotate(180deg);
        @include box-glow;
        border-radius: $radius-circular;
    }
}
</style>
